import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Banner = makeShortcode("Banner");
const Link = makeShortcode("Link");
const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="home"></a>
    <Banner mdxType="Banner" />`
    <a id="about"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "embodied-media--uci"
        }}>{`Embodied Media @ UCI`}</h2>
        <p>{`We connect people through applied cultural research in media art and design, producing tangible outcomes that incorporate creative exhibition and performance.`}</p>
        <p>{`Directed by `}<span className='text-nowrap'><a parentName="p" {...{
              "href": "https://jc.embodied.net/"
            }}>{`Professor John Crawford`}</a>{`,`}</span>{` the `}<span className='text-nowrap'>{`Embodied Media Research Group`}</span>{` (EMRG) is based at `}<span className='text-nowrap'>{`University of California, Irvine.`}</span>{` Our work integrates socially engaged artmaking and connected design thinking with emergent media production. We create compelling interactive experiences building upon the transformational technologies of our time.`}</p>
      </header>
      <div className="image filtered" data-position="center">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHEMAX/xAAWEAEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQEAAQUCSbG//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERMSFBUf/aAAgBAQABPyHtRhsUdo//2gAMAwEAAgADAAAAEA/f/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QR//EABURAQEAAAAAAAAAAAAAAAAAABEA/9oACAECAQE/EBJv/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMUFxgeH/2gAIAQEAAT8QaxFLZvqIOSpRZMw/J5P/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Leaf emerging from cracked soil",
              "title": "Leaf emerging from cracked soil",
              "src": "/static/3cfdec3b454125bf4fd49164b35ad648/111a0/emergence-cracked-soil_3x1.jpg",
              "srcSet": ["/static/3cfdec3b454125bf4fd49164b35ad648/3ad8d/emergence-cracked-soil_3x1.jpg 600w", "/static/3cfdec3b454125bf4fd49164b35ad648/6c738/emergence-cracked-soil_3x1.jpg 1200w", "/static/3cfdec3b454125bf4fd49164b35ad648/111a0/emergence-cracked-soil_3x1.jpg 2400w"],
              "sizes": "(max-width: 2400px) 100vw, 2400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </div>
      <div className="features">
        <section>
          <span className="icon solid fa-compress-arrows-alt major accent2"></span>
          <h3 {...{
            "id": "socially-engaged-artmaking"
          }}>{`Socially Engaged Artmaking`}</h3>
          <p>{`In collaboration with our communities, we make art that grapples with environmental, cultural and political issues, considering both immediate relevance and future potential for societal change.`}</p>
        </section>
        <section>
          <span className="icon solid fa-bezier-curve major accent4"></span>
          <h3 {...{
            "id": "connected-design-thinking"
          }}>{`Connected Design Thinking`}</h3>
          <p>{`We employ connected, responsible methods based on empathy, ideation, intuition and iteration to guide research and development toward solutions based on people's lived experience and actual needs.`}</p>
        </section>
        <section>
          <span className="icon solid fa-seedling major accent3"></span>
          <h3 {...{
            "id": "emergent-media-production"
          }}>{`Emergent Media production`}</h3>
          <p>{`We engage with new forms of expression enabled by emerging technologies, perhaps turning these media in directions not foreseen by their creators, opening new pathways for creative thinking.`}</p>
        </section>
      </div>
    </section>
    <a id="work"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "current-work"
        }}>{`Current Work`}</h2>
        <p>{`Our `}<a parentName="p" {...{
            "href": "/project/"
          }}>{`projects`}</a>{` are dedicated to creating diverse collaborative environments where people engage with challenging ideas in tangible, sensorial and experiential ways. Our `}<a parentName="p" {...{
            "href": "/course/"
          }}>{`courses`}</a>{` and `}<a parentName="p" {...{
            "href": "/collab/"
          }}>{`collaboratories`}</a>{` nurture and engage technology-savvy art makers, storytellers and designers, enabling and inspiring them as changemakers.`}</p>
 </header>
      <div className="inner"><div className="posts">
          <article>
            <Link to="/project/" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "2160px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAQAE/9oADAMBAAIQAxAAAAGvRknF0FADlyF1wE3/xAAcEAACAgIDAAAAAAAAAAAAAAABAgADBBARIjH/2gAIAQEAAQUCKNK01axlR7LkJwTE9n//xAAbEQACAQUAAAAAAAAAAAAAAAAAAQIDERMhMf/aAAgBAwEBPwGVPZiH0sj/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEv/aAAgBAgEBPwFzN3//xAAdEAAABQUAAAAAAAAAAAAAAAAAARARIQIxMnGB/9oACAEBAAY/ApJehjhrjFKtJ//EABsQAQEBAQADAQAAAAAAAAAAAAERADEhQXFR/9oACAEBAAE/IQeJP3uK0pf33mHchOBDnUjFY3UxGN5krXyq7p83/9oADAMBAAIAAwAAABDzOP8A/8QAGhEAAwADAQAAAAAAAAAAAAAAAAERITFB8P/aAAgBAwEBPxC2ShAij3BQ0f/EABgRAAMBAQAAAAAAAAAAAAAAAAABERBB/9oACAECAQE/EIKLHBt0/8QAHxABAQACAgEFAAAAAAAAAAAAAREAMSFBYVFxgZHR/9oACAEBAAE/ECVdlVDF4mMgaDkfKTXnAkQJsuDxSR3L0vuYMkJbw9MFGlYqKnnAkpBPs/MRSBkdgawYDOEOOs//2Q==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Dancers performing in Living in the Tempest",
                    "title": "Dancers performing in Living in the Tempest",
                    "src": "/static/7ba2505c327fe6b197c074d7c7fb3bf7/a8a4d/tempest-2018-2037_1x1.jpg",
                    "srcSet": ["/static/7ba2505c327fe6b197c074d7c7fb3bf7/3ad8d/tempest-2018-2037_1x1.jpg 600w", "/static/7ba2505c327fe6b197c074d7c7fb3bf7/6c738/tempest-2018-2037_1x1.jpg 1200w", "/static/7ba2505c327fe6b197c074d7c7fb3bf7/a8a4d/tempest-2018-2037_1x1.jpg 2160w"],
                    "sizes": "(max-width: 2160px) 100vw, 2160px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <p><strong parentName="p">{`Projects`}</strong>{` that deploy emerging technologies to make art with relevant impact for social good.`}</p>
            <ul className="actions special">
  <li><Link to="/project/" className="button primary small" mdxType="Link">Project Info</Link></li>
            </ul>
          </article>
          <article>
            <Link to="/course/" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "1352px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQBAwL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABn5e8WxMJOJJor//EABoQAAMBAQEBAAAAAAAAAAAAAAABEQIDEiP/2gAIAQEAAQUCxijz9VyF2RXXss509NH/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAbEAACAgMBAAAAAAAAAAAAAAAAARAxEiFxgf/aAAgBAQAGPwI2ooTx5HsWf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBMVFxkaH/2gAIAQEAAT8hTIJ2yJqvcB18Mwuilwo8/JdQ3y3LUFwxhT//2gAMAwEAAgADAAAAEAAQwv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQMBAT8QALE8tv/EABgRAAMBAQAAAAAAAAAAAAAAAAABEUEQ/9oACAECAQE/EK3hTXP/xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAxIUFRcf/aAAgBAQABPxA+hDuQIJUQWUyhHAp5xAQlZEw4xajRne6OYf1mbu7HoTuuxIcF5hoR8u//2Q==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Person exploring an augmented reality system",
                    "title": "Person exploring an augmented reality system",
                    "src": "/static/4a67c54c314d399c80ffd73c748da5a3/f2333/augmented-reality-hands_1x1.jpg",
                    "srcSet": ["/static/4a67c54c314d399c80ffd73c748da5a3/3ad8d/augmented-reality-hands_1x1.jpg 600w", "/static/4a67c54c314d399c80ffd73c748da5a3/6c738/augmented-reality-hands_1x1.jpg 1200w", "/static/4a67c54c314d399c80ffd73c748da5a3/f2333/augmented-reality-hands_1x1.jpg 1352w"],
                    "sizes": "(max-width: 1352px) 100vw, 1352px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <p><strong parentName="p">{`Courses`}</strong>{` by Prof. John Crawford and associated faculty for students in all areas of the UCI campus.`}</p>
            <ul className="actions special">
  <li><Link to="/course/" className="button primary small" mdxType="Link">Course Info</Link></li>
            </ul>
          </article>
          <article>
            <Link to="/collab/" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "1816px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAAByNPPJ2xFdLMqAT//xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAwQSE//aAAgBAQABBQLM57RbL7ADOquWDV5GXBDP/8QAGxEAAQQDAAAAAAAAAAAAAAAAAAECEBESIVH/2gAIAQMBAT8BrZm7gkf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAhASUf/aAAgBAgEBPwEquz//xAAeEAACAgAHAAAAAAAAAAAAAAABEQAhEBIgIkFRcf/aAAgBAQAGPwIuUWOThmfomyh0pa0f/8QAHBAAAwEAAgMAAAAAAAAAAAAAAAERITFRYaHh/9oACAEBAAE/IYPlRU4rsfRIYQnj5mMzgv0EzUR9NiauYU1azT0//9oADAMBAAIAAwAAABCo54L/xAAZEQEBAAMBAAAAAAAAAAAAAAABABEhMVH/2gAIAQMBAT8QVs8vGplc3//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EG8UYpaHwp//xAAbEAEBAQEBAAMAAAAAAAAAAAABEQAhMUFRYf/aAAgBAQABPxBXFLHI4CFAJ2H5uQhHPLhRurDoE8+8Qc5VFX9eVxStNek0ckAkH3IatPbgOgU5d//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "People planting together",
                    "title": "People planting together",
                    "src": "/static/75e8d3e212291ac1e00a2398362f9b4f/a4d83/people-planting-together_1x1.jpg",
                    "srcSet": ["/static/75e8d3e212291ac1e00a2398362f9b4f/3ad8d/people-planting-together_1x1.jpg 600w", "/static/75e8d3e212291ac1e00a2398362f9b4f/6c738/people-planting-together_1x1.jpg 1200w", "/static/75e8d3e212291ac1e00a2398362f9b4f/a4d83/people-planting-together_1x1.jpg 1816w"],
                    "sizes": "(max-width: 1816px) 100vw, 1816px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <p><strong parentName="p">{`Collaboratories`}</strong>{` connecting students and faculty in collaborations with other leading institutions.`}</p>
            <ul className="actions special">
  <li><Link to="/collab/" className="button primary small" mdxType="Link">Collaboratory Info</Link></li>
            </ul>
          </article>
        </div></div>
    </section>
    <section className="main style2">
  <footer>
    <div className="row aln-center gtr-50 gtr-uniform">
      <NavSecondary mdxType="NavSecondary" />
    </div>
    <div className="row">
      <div className="col-12 footnote">
        Additional Image Credits: Roman Bodnarchuk, HQuality, Linus Nyland, Skye Schmidt, @lovelyday12
      </div>
    </div>
  </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      